export const LED_BY_OPTIONS = {
  cognus_commons: 'CC',
  health_system: 'HS',
  cognus_for_community: 'CfC',
  community: 'CO',
  cognus_for_health_system: 'CfH'
}
export const LED_BY_LABEL = {
  cognus_commons: 'Cognus Commons',
  health_system: 'Health System',
  cognus_for_community: 'Cognus for Community',
  community: 'Community',
  cognus_for_health_system: 'Cognus for Health System'
}
export const LED_BY_VALUE = {
  COGNUS_COMMONS: 'cognus_commons',
  HEALTH_SYSTEM: 'health_system',
  COGNUS_FOR_COMMUNITY: 'cognus_for_community',
  COMMUNITY: 'community',
  COGNUS_FOR_HEALTH_SYSTEM: 'cognus_for_health_system'
}
